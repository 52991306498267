export const projectsList = [
    {
        id: 2,
        picture: 'project-portfolio.jpg',
        name: 'Portfolio',
        year: '2022',
        techno: ['react', 'API wordpress', 'bulma', 'javascript'],
        description: `Vous y êtes déjà, voici mon portfolio. \n
        
        Ce projet m'a permis de faire mes premières armes avec React (cours suivi avec l'excellent formateur Maximilian Schwarzmuller). \n

        Pour me concentrer sur cette techno et utiliser un CMS pour la partie Blog (qui sera prochainement en ligne), j'ai utilisé Strapi et Bulma pour rapidement mettre en place l'API le CSS.`,
        github: null,
        url: 'https://alexandrabercoff.fr',
    },
    {
        id: 3,
        picture: 'project-xpi.jpg',
        name: 'Expérience Immersive : wordpress',
        year: '2017-2021',
        techno: ['wordpress', 'php'],
        description: `Au début de cette aventure, j'ai débuté mon blog Wordpress avec un template existant mais depuis 4 ans j'explore ce CMS et PHP pour me permettre de le personnaliser et de concevoir les plugins en manipulant une base de données de plus en plus riche (agrémentée avec ACF). \n
        
        J'ai été rejoint par des coéquipiers qui rédigent aujourd'hui une grande partie des articles. \n
        
        A venir: repartir d'un starter theme pour concevoir un thème propre avec uniquement les fonctions qui m'intéressent.`,
        github: null,
        url: 'https://experienceimmersive.fr',
    },
    {
        id: 4,
        picture: 'project-hint.jpg',
        name: 'Hint Boardgame',
        year: '2021',
        techno: ['nodejs', 'express', 'postgresql', 'sqitch', 'pug'],
        description: ` Une application pour fournir des "indices" dans le cadre d'un jeu de piste "maison". 
        
        J'ai repris des éléments du jeu de société "Subterra" pour permettre au joueur de recevoir via l'application des indices lorsqu'il effectue certaines actions dans le jeu de société.
        
        Ce projet a dû être réalisé en peu de temps car il devait être terminé avant la date d'anniversaire de ma compagne. C'est pourquoi j'ai utilisé Pug pour rapidement mettre en place le front`,
        github: 'https://github.com/Alekiel42/intBoardgame',
        url: null,
    },
    {
        id: 5,
        picture: 'project-matrice.jpeg',
        name: 'Matrice (géocaching)',
        year: '2021',
        techno: ['nodejs', 'express', 'postgresql', 'react'],
        description: `Il s'agit d'une interface que j'ai réalisée dans le cadre d'une "mystery" que j'ai posée (les géocacheurs sauront de quoi je parle!). 

        Les joueurs doivent deviner les symboles qui dessinent ma matrice. En entrant le bon code, ils auront les coordonnées GPS de la cache à trouver. 

        Lisez le Readme pour avoir un code "démo" et tester l'application.`,
        github: 'https://github.com/Alekiel42/gc_matrice_bonus_front',
        url: 'https://matrice-gc.herokuapp.com/',
    },
    {
        id: 6,
        picture: 'project-cani.jpeg',
        name: 'Cani-potes',
        year: '2021',
        techno: ['nodejs', 'express', 'postgresql', 'Sqitch', 'Multer', 'AWS'],
        description: `Ce projet a été réalisé dans le cadre du projet de fin de formation à l'école O'Clock. 
        
        Il s'agit d'un réseau social de rencontre entre maîtres de chiens réalisé en équipe de 5 développeurs durant un mois (4 sprints)

        Vous pouvez consulter la démonstration vidéo ici : https://youtu.be/jrl_DBlTLyM?t=1536`,
        github: 'https://github.com/Alekiel42/Cani-potes',
        url: 'https://cani-potes.surge.sh',
    },
    {
        id: 7,
        picture: 'project-astro.jpg',
        name: 'Astro Dev',
        year: '2021',
        techno: ['vuejs', 'tailwind', 'javascript'],
        description: `Au départ il s'agissait d'un projet me permettant d'explorer et découvrir VueJs. Néanmoins, quand il s'agit d'exploration spatiale les idées se multiplient, et je compte transformer Astro-Dev en projet plus complet. 
        
        Le site permet d'afficher de manière aléatoire les photos "Astronomy Picture of the Day" de la NASA et de les rajouter ses photos préférées dans sa liste de favoris.

Il est possible de "voyager" dans le système solaire pour avoir plus d'informations sur les planètes et modifier la couleur du site.`,
        github: 'https://github.com/Alekiel42/astroDev',
        url: null,
    },
    {
        id: 8,
        picture: 'project-valise.jpg',
        name: 'Dans ma valise',
        year: '2021',
        techno: ['vuejs', 'css', 'javascript'],
        description: `Cette application permet d'afficher une liste d'objets à prendre pour ses voyages en précisant le lieu où il est rangé.

        On peut filtrer par type de voyages "2 jours + rando" ou "1 semaine + hiver" etc. Ceci permet de n'afficher que les objets utiles pour ce type de voyage.
        
        L'utilisateur peut cliquer sur l'objet pour indiquer qu'il est bien dans sa valise.`,
        github: 'https://github.com/Alekiel42/dans-ma-valise',
        url: null,
    },
    {
        id: 9,
        picture: 'projet-dmv-back.png',
        name: 'Dans ma valise back',
        year: '2023',
        techno: ['nodejs', 'tsoa', 'typescript', 'mongodb', 'kafka'],
        description: `En cours de développement.
        
        Il s'agit de la partie back de Dans ma valise. 
        
        Tout le projet est documenté notamment dans mon blog pour expliquer mes choix techniques et expliquer certains concepts. `,
        github: 'https://github.com/Alekiel42/dans-ma-valise-back',
        url: null,
    },
]

