import { experiencesList } from '../datas/experiencesList';

const Experiences = () => {
    // remonter le mapping dans parent ? 

    return (
        <section className="container">
            {experiencesList.map((exp) => (

                <article className="box media p-4 mx-5" key={exp.id}>
                    <div className="media-left">
                        <figure className="image is-64x64">
                            <img src={`./img/${exp.logo}`} alt={`logo de ${exp.location}`} />
                        </figure>
                    </div>
                    <div className="media-content">
                        <h2 className="title is-4 mb-0">{exp.title}</h2>
                        <div className="has-text-grey is-size-7">
                            {exp.start}
                            {exp.end !== null && ` - ${exp.end}`}
                        </div>
                        <span className="icon-text has-text-grey is-size-7 pb-2">
                            <span icon="icon">
                                <i className="fas fa-map-marker-alt mr-1"></i>
                            </span>
                            <span>{exp.city}</span>
                        </span>

                        <div className="mb-3">
                            {exp.description.split("\n").map((text, index) => {
                                return <div key={index}>{text}</div>;
                            })}

                        </div>
                        <div>
                            <span className="tag is-info mx-1">{exp.domaine}</span>
                            <span className="tag is-info mx-1">{exp.category}</span>
                        </div>
                    </div>
                </article>
            ))}
        </section>

    )
}

export default Experiences;