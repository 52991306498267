import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from "./NotFound";

const SinglePost = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [tagNames, setTagNames] = useState([]);
    const [featuredMedia, setFeaturedMedia] = useState(null);

    useEffect(() => {
        fetch(`https://www.api.blogfolio.alexandrabercoff.fr/wp-json/wp/v2/posts?slug=${slug}&_fields=title,content,tags,featured_media,modified`)
            .then(response => response.json())
            .then(data => setPost(data[0]))
            .catch(error => console.error('Erreur lors de la récupération des données : ', error));
    }, [slug]);


    useEffect(() => {
        if (post && post.tags && post.tags.length > 0) {
            const tagIds = post.tags;
            const tagPromises = tagIds.map(tagId => (
                fetch(`https://www.api.blogfolio.alexandrabercoff.fr/wp-json/wp/v2/tags/${tagId}?_fields=name`)
                    .then(response => response.json())
                    .then(data => data.name)
                    .catch(error => console.error('Erreur lors de la récupération des noms de tags : ', error))
            ));

            Promise.all(tagPromises)
                .then(names => setTagNames(names))
                .catch(error => console.error('Erreur lors de la récupération des noms de tags : ', error));
        }
    }, [post]);

    useEffect(() => {
        if (post && post.featured_media) {
            const mediaId = post.featured_media;
            fetch(`https://www.api.blogfolio.alexandrabercoff.fr/wp-json/wp/v2/media/${mediaId}`)
                .then(response => response.json())
                .then(data => setFeaturedMedia(data))
                .catch(error => console.error('Erreur lors de la récupération des détails de l\'image : ', error));
        }
    }, [post]);

    if (post === null) {
        return <div className="container">Chargement en cours...</div>;
    }

    if (post === undefined) {
        return <NotFound />;
    }

    //todo the sale in blogcards
    const isoDate = post.modified;
    const dateObj = new Date(isoDate);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = dateObj.toLocaleString('fr-FR', options);

    return (
        <div className="post">
            {featuredMedia && (
                <img src={featuredMedia.source_url} alt={featuredMedia.alt_text} className="post_featured-image"/>
            )}
            <h1 className="title is-1 mt-2 mb-0">{post.title.rendered}</h1>
            <p className="is-size-6 mb-4">Mis à jour le {formattedDate}</p>
            <div className="tags">
                {tagNames.map(tagName => (
                    <span key={tagName} className="tag is-info mr-2 my-1">
                        {tagName}
                    </span>
                ))}
            </div>

            <div className="content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </div>
    );
};

export default SinglePost;
