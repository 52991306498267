
const About = () => {  

    return(
        <div className="about">
            <h1 className="title">A propos de moi</h1>
            <h2 className="title is-4">Une collaboratrice n'est pas qu'une développeuse </h2>
            <p className="my-3">
            Je fais partie de cette catégorie de personnes qui adorent apprendre, et ce dans de nombreux secteurs. Bien que j'adore l'univers de la programmation informatique, ma vie s'articule aussi autour d'autres domaines.
             </p>
            <p className="my-3">
            J'adore les escape games, je tiens un blog sur ce sujet depuis 2017, et j'en ai joué plusieurs centaines dans le monde entier. J'adore résoudre des énigmes et des problèmes ! Les échecs me passionnent aussi énormément et offrent d'autres problématiques toujours intéressantes. À travers cette pratique, je travaille ma concentration, ma gestion des émotions et ma patience. Quand parfois je souhaite jouer ailleurs que sur un échiquier, je vais piocher dans ma ludothèque l'un de mes jeux de société. Mes préférés ? Earth, Imaginarium, Tzol'kin... et bien d'autres en fait !   
            </p>
            <p className="my-3">
            Je jongle entre ma semaine digitale avec mes week-ends ou mes vacances dans la nature. J'aime explorer de nouvelles montagnes, j'ai toujours une furieuse envie d'aller toujours plus haut et plus loin, et parfois en poussant un peu trop mes limites !   
            </p>
            <p className="my-3">
            Je joue aussi un peu de musique, surtout de la batterie. J'ai bien essayé d'apprendre le piano, la basse, la guitare, et même l'harmonica (que j'ai déréglé après l'avoir démonté "par curiosité"), mais la batterie est mon coup de cœur, car cet instrument implique tout le corps qui se fond dans le rythme, un peu comme la danse. Et si je veux que ce soit encore plus "sportif", je vais chercher mon casque VR et faire quelques parties de Beat Saber !            
            </p>
        </div>
   );

}

export default About;


