import {Link} from "react-router-dom";

const Menu = (props) => {
    return(
        <aside className="menu">
            <ul>
                <li  className="menu_item">
                    <Link to={"/qui-suis-je"}>
                            <img className="menu_item-image" src="/img/alexandra_bercoff.jpeg" alt="Alexandra Bercoff " />
                    </Link>
                </li>
                <Link  to={"/experiences"}>
                    <li className="menu_item">
                        <i className="menu_item-icon fas fa-address-card"></i>
                        <p className="menu_item-text">XP</p>
                    </li>
                </Link>
                <Link to={"/projects"}>
                    <li className="menu_item">
                        <i className="menu_item-icon fas fa-cogs"></i>
                        <p className="menu_item-text">Projets</p>
                    </li>
                </Link>
                <Link to={"/blog"}>
                    <li className="menu_item">
                        <i className="menu_item-icon far fa-newspaper"></i>
                        <p className="menu_item-text">Blog</p>
                    </li>
                </Link>
            </ul>
        </aside>
    )
}

export default Menu;