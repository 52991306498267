import { projectsList } from '../datas/projectsList';

const projectsListSort = projectsList.reverse();

const Project = () => {
    return (
        <section className="container is-flex is-flex-wrap-wrap is-justify-content-center">
            {projectsListSort.map((project) => (
                <div className="card mx-3 my-1 project" key={`project-${project.id}`}>
                    <div className="card-image is-16by9">
                        <figure className="image">
                            <img src={`./img/${project.picture}`} alt={project.name} />
                        </figure>
                    </div>

                    <div className="card-content is-size-7 is-relative">

                        <div>
                            {project.techno.map((techno) => (
                                <span className="tag is-info mr-2 my-1">{techno}</span>
                            ))}
                        </div>
                        <h2 className="title is-4 my-3">{project.name}</h2>

                        <div className="mb-5">
                            {project.description.split("\n").map((text, index) => {
                                return <div className="my-2" key={index}>{text}</div>;
                            })}
                        </div>

                        {
                            project.url &&
                            <div className="my-2">
                                <i className="fas fa-link   mr-2"></i>
                                <span><a href={`${project.url}`} target="_blank" rel="noreferrer">{project.url}</a></span>
                            </div>
                        }

                        {
                            project.github &&
                            <div className="my-2">
                                <i className="fab fa-github mr-2"></i>
                                <span><a href={`${project.github}`} target="_blank" rel="noreferrer">Github : {project.name}</a></span>
                            </div>
                        }

                    </div>
                </div>

            ))}
        </section>
    )
}

export default Project;