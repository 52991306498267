import {Link} from "react-router-dom";

const iconData = [
    "fab fa-node-js",
    "fab fa-js-square",
    "fab fa-vuejs",
    "fab fa-react",
];

const Menu = (props) => {
    return(
       <div className="container">
           <div className="social-media">
               <div className="social-media_item">
                   <i className="fab fa-github mr-2"></i>
                   <span><a href="https://github.com/Alekiel42" target="_blank" rel="noreferrer">github.com/Alekiel42</a></span>
               </div>
               <div className="social-media_item">
                   <i className="fab fa-instagram mr-2"></i>
                   <span><a href="https://www.instagram.com/this.alexandra" target="_blank" rel="noreferrer">instagram.com/this.alexandra</a></span>
               </div>
           </div>
           <h2 className="presentation">
               <span role="img" aria-label="hand-wave">👋</span>
               <span className="presentation_first-part">Hello ! I am</span>
               <span className="presentation_name">Alex</span>
           </h2>
           <div className="icon-tech">
               {iconData.map((className, index) => (
                   <i key={index} className={className+ " icon-tech_item"}></i>
               ))}
           </div>
       </div>
    )
}

export default Menu;