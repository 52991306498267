import React, { Component } from 'react';
import Menu from './Menu';
import About from './About';
import Experiences from './Experiences';
import Projects from './Projects';
import BlogCards from './BlogCards';
import SinglePost from './SinglePost';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './../style/style.scss';
import Hello from "./Hello";
import NotFound from "./NotFound";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="main">
                    <Menu />
                    <Routes>
                        <Route path="/" element={<Hello />} />
                        <Route path="/qui-suis-je" element={<About />} />
                        <Route path="/experiences" element={<Experiences />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/blog" element={<BlogCards />} />
                        <Route path="/blog/:slug" element={<SinglePost />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </Router>
        );
    }
}

export default App;


