import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SinglePost from './SinglePost';

const BlogCards = () => {
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetch('https://www.api.blogfolio.alexandrabercoff.fr/wp-json/wp/v2/posts?_embed')
            .then(response => response.json())
            .then(data => {
                setPosts(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données : ', error);
                setIsLoading(false);
            });
    }, []);

    const handlePostClick = (post) => {
        setSelectedPost(post);
    };

    return (
        <section className="container is-flex is-flex-wrap-wrap">
            {isLoading ? (
                <p className="container">Chargement en cours...</p>
            ) : selectedPost ? (
                <SinglePost post={selectedPost} />
            ) : (
                posts.map(post => (
                    <Link to={`/blog/${post.slug}`}key={post.id}>
                        <div className="card mx-2" key={post.id} onClick={() => handlePostClick()}>
                            <div className='card-image is-16by9'>
                                <figure className="image">
                                    <img src={post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url} alt="" />
                                </figure>
                            </div>
                            <div className='card-content px-3'>
                                <h3 className='title is-6 mb-1'>{post.title.rendered}</h3>
                                <p className="text-gray-400 is-size-7 mb-4">{formatDate(post.modified)}</p>
                                <div>
                                    {post._embedded['wp:term'][1].map((tag) => (
                                        <span className="tag is-info mr-2 my-1">{tag.name}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))
            )}
        </section>
    );
};

function formatDate(isoDate) {
    const dateObj = new Date(isoDate);
    const options = { year: 'numeric', month: 'long' };
    return dateObj.toLocaleString('fr-FR', options);
}
export default BlogCards;
