export const experiencesList = [
    {
        id: 12,
        logo: 'logo-manomano.jpg',
        title: 'Software Engineer Back NodeJs Kotlin',
        start: '2022-07-18',
        end: 'en poste',
        location: 'ManoMano',
        city: 'Bordeaux',
        country: 'France',
        domaine: 'digital',
        description: `- Développement back-end (NodeJs / Kotlin ) \n - Postgres \n - Kafka \n - Tests (Jest / Junit) \n - Monitoring (Datadog)`,
        url: null,
        category: 'pro'
    },
    {
        id: 11,
        logo: 'logo-wedge.jpeg',
        title: 'Développeuse Front VueJs',
        start: '2022-02-01',
        end: '2022-07-17',
        location: 'Wedge Digital',
        city: 'Bordeaux',
        country: 'France',
        domaine: 'digital',
        description: `- Développement front-end (Vue-js) \n - Intégration HTML CSS \n - Tests (Cypress, Jest)`,
        url: null,
        category: 'pro'
    },
    {
        id: 10,
        logo: 'logo-oclock.png',
        title: 'Formation Développeur Fullstack Javascript',
        start: '2021-01-14',
        end: '2021-10-15',
        location: 'Ecole O\'Clock',
        city: 'Bordeaux',
        country: 'France',
        domaine: 'digital',
        description: `Cours et conception de nombreux projets me permettant d' explorer Javascript, Nodejs, React, Expressjs, Sequelize, MongoDB et Postgres`,
        url: null,
        category: 'formation'
    },
    {
        id: 9,
        logo: 'logo-xpi.png',
        title: 'Fondatrice et webmaster',
        start: '2017-01-29',
        end: null,
        location: 'Expérience Immersive',
        city: 'Bordeaux',
        country: 'France',
        domaine: 'digital',
        description: `- Création de templates et de plugins Wordpress. Gestion des méta données entre les articles \n 
        - Utilisation de JS / PHP / SQL / CSS`,
        url: 'https://experienceimmersive.fr',
        category: 'perso'
    },
    {
        id: 8,
        logo: 'logo-gaiactica.jpg',
        title: 'Game Master',
        start: '2019-09-01',
        end: '2020-06-01',
        location: 'Gaïactica',
        city: 'Bordeaux',
        country: 'France',
        domaine: 'escape game',
        description: 'Maître du jeu, Aide à la conception de scénarios et animation de jeux pour les entreprises.',
        url: null,
        category: 'pro'
    },
    {
        id: 7,
        logo: 'logo-echappe-toi.jpeg',
        title: 'Service clientèle',
        start: '2018-09-01',
        end: '2018-12-01',
        location: 'Echappe-toi Escape Game',
        city: 'Montréal',
        country: 'Canada',
        domaine: 'escape game',
        description: `- Accueil des clients \n 
        - Briefing proposé en anglais et français (avec quelques expressions québecoises, je m'adapte rapidement!)`,
        url: null,
        category: 'pro'
    },
    {
        id: 6,
        logo: 'logo-vm.jpg',
        title: 'Chef de projet fidélisation et contenu',
        start: '2017-03-20',
        end: '2018-04-20',
        location: 'Vision du Monde',
        city: 'Paris',
        country: 'France',
        domaine: 'digital',
        description: `- Community Management \n 
        - Gestion campagnes Facebook Ads \n 
        - Gestion du planning éditoriale entre les différents services de l'ONG \n 
        - Graphisme et garante du respect de la charte graphique`,
        url: null,
        category: 'pro'
    },
    {
        id: 5,
        logo: 'logo-lamazuna.jpg',
        title: 'Webmaster et Responsable Commerciale',
        start: '2015-09-01',
        end: '2016-12-01',
        location: 'Lamazuna',
        city: 'Paris',
        country: 'France',
        domaine: 'digital',
        description: `- Webmastering (Prestashop) \n 
        - Optimisation SEO - Gestion campagnes SEA \n
        - Relation blogueurs \n 
        - Développement de la marque en boutiques`,
        url: null,
        category: 'pro'
    },
    {
        id: 4,
        logo: 'logo-nbc.png',
        title: 'Community Manager (stage)',
        start: '2011-09-01',
        end: '2012-06-01',
        location: 'NBC Universal',
        city: 'Paris',
        country: 'France',
        domaine: 'digital',
        description: `- Développement et animation de la communauté Syfy et 13ème RUE sur Facebook, Twitter, Google+ et Youtube \n 
        - Analyse du trafic et élaboration d’une stratégie communautaire (google analytics, facebook Insights)\n 
        - Gestion du contenu du site Syfy.fr (jeux, blog, contenus éditoriaux, vidéos, etc.) \n 
        - Veille concurrentielle et d’actualité autour des thématiques de Syfy et de 13ème RUE.`,
        url: null,
        category: 'pro'
    },
    {
        id: 3,
        logo: 'logo-imt.jpg',
        title: 'Fabrication numérique et prototypage rapide',
        start: '2019-04-01',
        end: '2019-10-01',
        location: 'IMT Atlantique',
        city: 'Paris',
        country: 'France',
        domaine: 'numérique',
        description: `- Conception de prototype à l'aide d'imprimantes 3D, découpeuses laser et de cartes Arduino \n 
        - Développement de programmes Arduino. \n 
        - Mon projet de fin de formation, réalisé en équipe, a reçu deux prix: prix technologique et prix du public `,
        url: null,
        category: 'formation'
    },
    {
        id: 2,
        logo: 'logo-ocr.png',
        title: 'Certifications OpenClassrooms',
        start: '2020-01-01',
        end: null,
        location: 'OpenClassrooms',
        city: 'Bordeaux',
        country: 'France',
        domaine: 'numérique',
        description: `- mai 2020 : Débutez avec React \n 
                    - octobre 2020 : Simplifiez-vous le CSS avec Sass \n 
                    - septembre 2020 : Apprenez à programmer avec Javascript `,
        url: null,
        category: 'formation'
    },
    {
        id: 1,
        logo: 'logo-sorbonne.jpg',
        title: 'Master pro Scénario, Réalisation, Production',
        start: '2010-09-01',
        end: '2011-06-25',
        location: 'Panthéon-Sorbonne',
        city: 'Paris',
        country: 'France',
        domaine: 'digital',
        description: 'Réalisation de films, écriture de scénarios',
        url: null,
        category: 'cinéma'
    },


]